@import './variables.module.scss';

.backgroundContent {
  position: fixed;
  z-index: -999999;
  top: 10%;
}

.interactiveContent {
  position: fixed;
  right: 0;
  top: 26%;
  margin-right: 2rem;
  width: 260px;

  & h1 {
    margin: 0;
    font-family: Mukta;
    font-size: 3rem;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: left;
  }
  & h2 {
    margin: 0;
    margin-top: 14px;
    margin-bottom: 24px;
    font-family: Mukta;
    font-size: 2rem;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
  }
  & p {
    color: $Color_LightGray500;
  }
  & a {
    display: table;
    padding: 10px;
    text-align: center;
    width: 100%;
    height: 1.75rem;
    color: $Color_White500;
    background: $Color_Green500;
    border-radius: 6px;
  }
}
